import React from 'react';
import Table from './Table';

const DieselConsumption = ({articleId, startDate, endDate}) => {
  const tableToolbar = () => {
    return (
      <></>
    )
  }

  return (
    <>
      <div className="fs-1 text-gray-600 py-3">
        {`${startDate} - ${endDate}`}
      </div>
      <Table tableToolbar={tableToolbar} articleId={articleId} />
    </>
  )
}

export default DieselConsumption;
