import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';

const Table = ({ billId, tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getBillChronicles = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination

    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], bill_id: billId }

    if (globalFilter) {
      params.query = globalFilter
    }

    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }

    const res = await axiosInstance.get(routes.billChronicles(), { params: params })

    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  };

  const columns = useMemo(
    () => [
      {
        id: 'created_at',
        header: t('time'),
        size: 10,
        minSize: 10,
        accessorFn: row => row?.attributes?.created_at,
        enableSorting: true,
        cell: ({ row }) => {
          return formatDate(row.original.attributes.created_at)
        },
      },
      {
        id: 'staff_name',
        header: t('staff'),
        accessorFn: row => row?.attributes?.staff_name,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'description',
        header: t('driver_inputs'),
        size: 300,
        minSize: 300,
        accessorFn: row => row?.attributes?.description,
        enableSorting: true,
        cell: row => (
          <div dangerouslySetInnerHTML={{ __html: row.getValue() }} />
        ),
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getBillChronicles}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
