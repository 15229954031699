import React from 'react';
import Table from './Table';

const BillChronicles = ({ billId }) => {
  const tableToolbar = () => {
    return (
      <></>
    )
  }

  return (
    <Table tableToolbar={tableToolbar} billId={billId} />
  )
}

export default BillChronicles;
