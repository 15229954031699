import React, { useState, useEffect } from 'react';
import Toastr from '../../../components/Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import EditableTextInput from '../../../components/Table/EditableTextInput';
import { useTranslation } from 'react-i18next';
import EditableSelectInput from "../../../components/Table/EditableSelectInput";
import classNames from 'classnames';
import ToggleSwitch from '../../ToggleSwitch';
import CurrencyInput from "../../../components/Table/EditableCurrencyInput";
import TableButton from '../../Table/TableButton';
import { Tooltip } from 'bootstrap'
import { QuestionIcon } from '../../../components/Icons';
import { TrashSquareIcon } from '../../../components/Icons';

const sidebar = ({ articleId, article, storeState }) => {
  const { t } = useTranslation()
  const [plantProtectionProduct, setPlantProtectionProduct] = useState({});
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [])

  useEffect(() => {
    article &&
      setPlantProtectionProduct(
        article?.article_detail?.attributes?.plant_protection_product?.attributes
      )
  }, [article])

  const toggleHtml = (attribute) => {
    const isActive = attribute == 'active' ?
      article[attribute] : article?.article_detail?.attributes[attribute]

    const label = isActive ? t('true') : t('false');

    return (
      !article?.article_detail?.attributes?.diesel || attribute != 'active' ? (
        <ToggleSwitch
          id={articleId}
          handleToggle={() => updateArticle(articleId, { [attribute]: !isActive })}
          attribute={attribute}
          active={isActive}
          label={label}
        />
      ) : (
        <span>{t('true')}</span>
      )
    );
  };

  const updateArticle = (id, attrs) => {
    const data = {
      article: {
        active: attrs.active,
        name: attrs.name,
        client_specific_number: attrs.client_specific_number
      },
      article_detail: {
        article_group_id: attrs.article_group?.value,
        factory_number: attrs.factory_number,
        description: attrs.description,
        unit_id: attrs.unit_id?.value,
        revenue_code_id: attrs.revenue_code_id,
        price_in: attrs.price_in,
        price_out: attrs.price_out,
        revenue_code_id: attrs.revenue_code_id?.value,
        add_charge_perc: attrs.add_charge_perc,
        stock_quantity: attrs.stock_quantity,
        hotel_flag: attrs.hotel_flag
      }
    }

    axiosInstance.put(routes.article(id), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        storeState(resp)
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getUnits = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      scope_name: 'default'
    }

    return axiosInstance.get(routes.units(), { params: params })
      .then((response) => {
        return response.data.data.map((unit) => ({
          value: unit.attributes.id,
          label: unit.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getRevenueCodes = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      scope_name: 'default',
      active: true,
      article: true,
      client_id: currentCustomizing.client_id
    }

    return axiosInstance.get(routes.revenueCodes(), { params: params })
      .then((response) => {
        return response.data.data.map((code) => ({
          value: code.attributes.id,
          label: code.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const updatePurchasePrice = () => {
    updateArticle(article.id,
      { price_in: centsToAmount(article.last_purchase_price.cents, 'en-US', 'decimal') }
    )
  }

  const getArticleGroups = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      scope_name: 'default'
    }

    return axiosInstance.get(routes.articleGroups(), { params: params })
      .then((response) => {
        return response.data.data.map((articleGroup) => ({
          value: articleGroup.attributes.id,
          label: articleGroup.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const articleGroupRow = () => {
    let content

    if (plantProtectionProduct) {
      content = t('article_group_not_changeable')
    } else if (article?.article_detail?.attributes?.diesel) {
      content = article?.article_group?.attributes.name
    } else {
      content =
      <EditableSelectInput
        id={article.id}
        attribute={'article_group'}
        returnObject
        placeholder={t('article_group')}
        initialValue={{ value: article?.article_group?.attributes?.id, label: article?.article_group?.attributes?.name }}
        handleSubmit={updateArticle}
        getEntities={getArticleGroups}
        isClearable={false}
      />
    }

    return (
      <>
        {content}
      </>
    )
  };

  const deleteArticle = () => {
    axiosInstance.delete(routes.article(article.id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        window.location.assign("/articles")
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
    }

  const centsToAmount = (cents, format = 'de-DE', style='currency') => {
    return new Intl.NumberFormat(format, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'EUR',
        style: style
      }).format(cents / 10000);
  };

  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8 top-0 position-md-sticky">
        <div className="card-header ribbon ribbon-end ribbon-clip">
          <span className="align-content-center fs-3 text-gray-800 fw-bold">
            {article.name}
          </span>
        </div>
        <div className="card-body">
          <div className="d-flex flex-stack fs-4 py-3">
            <div className={classNames("fw-bold rotate collapsible")} >
              {t('details')}
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4'>
            <div className="fw-bold align-content-center">
              {t("active")}
            </div>
            <div className="text-gray-600 cursor-pointer">
              {toggleHtml('active')}
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4 flex-stack'>
            <div className="fw-bold align-items-center d-flex">
              {t('shared.article_number')}
            </div>
            <div className="text-gray-600">
              <EditableTextInput
                id={articleId}
                attribute="client_specific_number"
                placeholder={t('shared.article_number')}
                initialValue={article.client_specific_number}
                handleSubmit={updateArticle}
              />
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4'>
            <div className="fw-bold align-items-center d-flex">
              {t('name')}
            </div>
            <div className="text-gray-600">
              <EditableTextInput
              id={articleId}
                attribute="name"
                placeholder={t('name')}
                initialValue={article.name}
                handleSubmit={updateArticle}
              />
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4'>
            <div className="fw-bold align-items-center d-flex">
              {t('shared.manufacturer_number')}
            </div>
            <div className="text-gray-600">
              <EditableTextInput
                id={articleId}
                attribute="factory_number"
                placeholder={t('shared.manufacturer_number')}
                initialValue={article.article_detail?.attributes?.factory_number || ' '}
                handleSubmit={updateArticle}
              />
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4'>
            <div className="fw-bold align-items-center d-flex">
              {t('article_group')}
            </div>
            <div className="text-gray-600">
              {articleGroupRow()}
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4'>
            <div className="fw-bold align-items-center d-flex">
              {t('comment')}
            </div>
            <div className="text-gray-600">
              <EditableTextInput
                id={articleId}
                attribute="description"
                placeholder={t('comment')}
                initialValue={article.article_detail?.attributes?.description || "N/A"}
                handleSubmit={updateArticle}
              />
            </div>
          </div>
          <div className="separator"></div>
          <div className='d-flex justify-content-between py-4'>
            <div className="fw-bold align-items-center d-flex">
              {t('shared.unit')}
            </div>
            <div className="text-gray-600">
              <EditableSelectInput
                id={article.id}
                attribute={'unit_id'}
                returnObject
                placeholder={t('shared.unit')}
                initialValue={{ value: article.article_detail?.attributes?.unit?.id, label: article.article_detail?.attributes?.unit?.attributes?.name }}
                handleSubmit={updateArticle}
                getEntities={getUnits}
                isClearable={false}
              />
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className={classNames("fw-bold rotate collapsible collapsed")} data-bs-toggle="collapse" href="#additional" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
              {t('additional_details')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div id="additional" className="collapse" >
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-between">
                {t('fixed_purchase_price')}
              </div>
              <div className='d-flex flex-column flex-end justify-content-end'>
                <div className="text-gray-600">
                  <CurrencyInput
                    id={articleId}
                    attribute="price_in"
                    initialValue={article?.article_detail?.attributes?.price_in_cents / 10000}
                    label={centsToAmount(article?.article_detail?.attributes?.price_in_cents)}
                    handleSubmit={updateArticle}
                  />
                </div>
                {article.last_purchase_price && article.last_purchase_price.cents !== article?.article_detail?.attributes?.price_in_cents && (
                  <TableButton
                    className="btn btn-primary btn-sm w-50"
                    type="button"
                    onClick={updatePurchasePrice}
                    title={`Use goods purchase price ${centsToAmount(article.last_purchase_price?.cents, 'en-US')}`}
                  />
                )}
              </div>
            </div>

            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('service_features.invoice_text')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('articles.invoice_text_title')}
                  className="btn p-0 text-hover-warning"
                >
                  <QuestionIcon className={"fs-4"} />
                </span>
              </div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={articleId}
                  attribute="bill_name"
                  placeholder={t('service_features.invoice_text')}
                  initialValue={article?.article_detail?.attributes?.bill_name || "-"}
                  handleSubmit={updateArticle}
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('shared.revenue_code')}
              </div>
              <div className="text-gray-600">
                <EditableSelectInput
                  id={article.id}
                  attribute={'revenue_code_id'}
                  returnObject
                  placeholder={t('shared.revenue_code')}
                  initialValue={{ value: article.article_detail?.attributes?.revenue_code?.id, label: article.article_detail?.attributes?.revenue_code?.attributes?.name }}
                  handleSubmit={updateArticle}
                  getEntities={getRevenueCodes}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('sales_surcharge')}
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                <EditableTextInput
                  id={article.id}
                  attribute="add_charge_perc"
                  placeholder={t('sales_surcharge')}
                  initialValue={article.article_detail?.attributes?.add_charge_perc}
                  handleSubmit={updateArticle}
                />
                <span className={'ms-2'}>%</span>
              </div>
            </div>

            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('selling_price')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('articles.sales_surcharge_title')}
                  className="btn p-0 text-hover-warning"
                >
                  <QuestionIcon className={"fs-4"} />
                </span>
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                <CurrencyInput
                  id={articleId}
                  attribute="price_out"
                  initialValue={article?.article_detail?.attributes?.price_out_cents / 10000}
                  label={centsToAmount(article?.article_detail?.attributes?.price_out_cents)}
                  handleSubmit={updateArticle}
                />
              </div>
            </div>

            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('stock')}
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                <EditableTextInput
                  id={article.id}
                  attribute="stock_quantity"
                  placeholder={t('stock')}
                  initialValue={article.article_detail?.attributes?.stock_quantity}
                  handleSubmit={updateArticle}
                />
                <span className={'ms-2'}>{article.article_detail?.attributes?.unit?.attributes?.name}</span>
              </div>
            </div>
            {currentCustomizing?.use_hotel_flag && (
              <>
                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('allowance')}
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-inverse"
                      data-bs-placement="top"
                      title={t('articles.trigger_title')}
                      className="btn p-0 text-hover-warning"
                    >
                      <QuestionIcon className={"fs-4"} />
                    </span>
                  </div>
                  <div className="text-gray-600 cursor-pointer">
                    {toggleHtml('hotel_flag')}
                  </div>
                </div>
              </>
            )}
          </div>

          {plantProtectionProduct && (
            <>
              <div className="d-flex flex-stack fs-4 py-3">
                <div className={classNames("fw-bold rotate collapsible collapsed")} data-bs-toggle="collapse" href="#plant_protection_products" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
                  {t('plant_protection_products')}
                  <span className="ms-2 rotate-180">
                    <i className="ki-duotone ki-down fs-3"></i>
                  </span>
                </div>
              </div>

              <div id="plant_protection_products" className="collapse" >
                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('plant_protection_products')}
                  </div>
                  <div className="text-gray-600">
                    {article.name}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('authorization_holder')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.authorisation_holder}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('approval_number')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.authorisation_number}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('formulation')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.type_of_formulation}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('active_ingredients')}
                  </div>
                  <div className="text-gray-600 text-end">
                    {plantProtectionProduct.active_substances}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('effective_areas')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.functions}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('area')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.fields_of_use}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('articles.application_in_the_home')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.authorised_for_amateur_gardening ? t('true') : t('false')}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('start_of_admission')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.start_date}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('end_of_registration')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.end_date}
                  </div>
                </div>

                <div className="separator"></div>
                <div className='d-flex justify-content-between py-4'>
                  <div className="fw-bold align-content-center">
                    {t('country')}
                  </div>
                  <div className="text-gray-600">
                    {plantProtectionProduct.country?.data?.attributes?.name}
                  </div>
                </div>
              </div>
            </>
          )}
          <>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('shared.delete')}
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                <div onClick={() => deleteArticle()}><TrashSquareIcon className="fs-2hx text-danger cursor-pointer" /></div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default sidebar;
