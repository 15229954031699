import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, articleId }) => {
  const { t, i18n } = useTranslation()

  const getArticleShowDisposed = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const urlParams = new URLSearchParams(window.location.search)
    const tabName = { tab: urlParams.get("tab") }

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], article_id: articleId }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.articleShowDisposed(), { params: params })
    params = { ...params, ...tabName }
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'order_name',
        header: t('order'),
        accessorFn: row => row?.attributes?.order_name,
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <a href={row?.original?.attributes?.order_link}>
              {row?.original?.attributes?.order_name}
            </a>
          )
        },
      },
      {
        id: 'order_short_date',
        accessorFn: row => row?.attributes?.order_short_date,
        header: t('date'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'quantity_with_unit',
        accessorFn: row => row?.attributes?.quantity_with_unit,
        header: t('quantity'),
        enableSorting: false,
        cell: row => row.getValue()
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getArticleShowDisposed}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
