import React, { useMemo, useState } from 'react';
import BaseTable from '../Table';
import { useTranslation } from 'react-i18next';
import {isEmpty} from 'lodash';
import {axiosInstance, routes} from '../../utils/api_base';
import {pushToHistory} from '../../utils/base';
import TableButton from '../Table/TableButton';
import ExpandableList from '../ExpandableList';

const Table = ({ tableToolbar, columnVisibility, unit_type}) => {
  const { t, i18n } = useTranslation()

  const getUnits = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], unit_type: unit_type }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.units(), { params: {...params, serialize: "full"} })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const expandableListHtml = (items) => {
    if (items.length === 0) return null

    return (
      <ExpandableList
        items={items}
        renderItem={(service) => (
          <a href={service.attributes.link}>{service.attributes.long_name}</a>
        )}
      />
    )
  }

  const serviceFieldsHtml = (row) => {
    const services = row?.original?.attributes?.service_fields || []
    return(
      <>
        {services.map((service, index) => {
          return <><a key={index} href={service.attributes.link}>{service.attributes.name}</a><br/></>
        })}
      </>
    )
  }

  const articleDetailsHtml = (row) => {
    const services = row?.original?.attributes?.article_details || []
    return(
      <>
        {services.map((service, index) => {
          return <><a key={index} href={service.attributes.link}>{service.attributes.name}</a><br/></>
        })}
      </>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: row => row?.attributes?.name,
        header: t('shared.name'),
        cell: row => row.getValue(),
      },
      {
        id: 'short_name',
        accessorFn: row => row?.attributes?.short_name,
        header: t('shared.short_name'),
        cell: row => row.getValue(),
      },
      {
        id: 'services',
        header: `${t('shared.use')} ${t('shared.performance')}`,
        cell: ({ row }) => {
          return expandableListHtml(row?.original?.attributes?.services || [])
        },
      },
      {
        id: 'service_fields',
        header: `${t('shared.use')} ${t('shared.query_field')}`,
        cell: ({ row }) => {
          return expandableListHtml(row?.original?.attributes?.service_fields || [])
        },
      },
      {
        id: 'article_details',
        header: `${t('shared.use')} ${t('shared.article')}`,
        cell: ({ row }) => {
          return expandableListHtml(row?.original?.attributes?.article_details || [])
        },
      },
      {
        id: 'ecode',
        accessorFn: row => row?.attributes?.ecode,
        header: t('units.e_invoice_code'),
        cell: row => row.getValue(),
      },
      {
        id: 'code',
        accessorFn: row => row?.attributes?.code,
        header: t('units.internal_code'),
        cell: row => row.getValue(),
      },
      {
        id: 'key',
        accessorFn: row => row?.attributes?.key,
        header: t('units.sort_key'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getUnits}
      columns={columns}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      initialSortDirection={'asc'}
      trigger={unit_type}
    />
  )
}

export default Table;
