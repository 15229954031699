import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import { isRequired } from '../../components/Form/validations';
import TableButton from '../../components/Table/TableButton';
import ErrorField from '../../components/Form/ErrorField';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'
import moment from "moment";
import DatePicker from '../../components/DatePicker';

const Create = ({ onClose, setTrigger}) => {
  const { t, i18n} = useTranslation();
  const currentDate = moment().toDate();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const onSubmit = async values => {
    const data = {
      diesel_price: {
        price: values.price,
        valid_from: Array.isArray(values.valid_from) ? values.valid_from[0] : values.valid_from
      }
    };

    axiosInstance.post(routes.dieselPrices(), data)
      .then(response => {
        Toastr({
          title: t('shared.success'),
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        setTrigger(Math.floor(Date.now() / 1000))
      }).catch(error => {

        const errorData = error.response.data
        if (errorData.error) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        price: '',
        valid_from: currentDate
      }}
      validate={values => {
        let errors = {}
        errors.price = isRequired(values.price)
        errors.valid_from = isRequired(values.valid_from)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="container">
              <div className='p-6'>
                <div className="row mb-4">
                  <div className="col">
                    <label>{t('price')}</label>
                    <Field
                      name="price"
                      component="input"
                      type="number"
                      step=".01"
                      className="form-control"
                    />
                    <ErrorField name="price" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label>{t('valid_from')}</label>
                    <Field
                      name="valid_from"
                      render={({ input }) => (
                        <DatePicker
                          options={{
                            defaultDate: currentDate,
                            dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'd-m-Y'),
                            onChange: input.onChange
                          }}
                        />
                      )}
                    />
                    <ErrorField name="valid_from" />
                  </div>
                </div>
                {submitError && <div className="form-error-message">{submitError}</div>}
                <div className="modal-footer">
                  <TableButton
                    className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                    type="button"
                    onClick={onClose}
                    title={t('cancel')}
                  />
                  <TableButton
                    className="btn btn-primary fw-semibold px-6"
                    type="submit"
                    disabled={submitting || pristine}
                    title={t('create')}
                  />
                </div>
              </div>
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create;
