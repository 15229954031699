import React, { useMemo, useState } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import ToggleSwitch from '../../components/ToggleSwitch';
import Toastr from '../../components/Toastr';

const Table = ({ tableToolbar, filters, year, month, cutoffDate, setShowCutoffDate, show_cutoff_date_status_id }) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(false)
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const urlParams = new URLSearchParams(window.location.search)
  const isInternal = urlParams.get('internal') == 'true'

  const getBillStatistic = async (pagination, sorting, globalFilter, customFilters) => {

    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = {
      page: pageIndex + 1, limit: pageSize,
      year: year, month: month
    }
    if (globalFilter) {
      params.query = globalFilter
    }

    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
      // Add a cutoff_date param only if the status is "open"
      if (customFilters.status == show_cutoff_date_status_id) {
        params = { ...params, cutoff_date: cutoffDate }
        setShowCutoffDate(true)
      } else {
        setShowCutoffDate(false)
      }
    }
    const res = await axiosInstance.get(routes.billsStatistic(), { params: params })

    if (isInternal) { params = { ...params, internal: isInternal } }
    pushToHistory(params)

    return (
      {
        rows: res.data.data,
        pageCount: res.data.meta.total_pages,
        rowCount: res.data.meta.total_count,
        meta: res.data.meta
      }
    )
  }

  const amountRow = (row) => {
    return (
      <span className={row?.row?.original?.attributes?.open_amount?.color_class}>
        {row?.row?.original?.attributes.open_amount?.value}
      </span>
    )
  }

  const updateCombination = (id, params) => {
    axiosInstance.put(routes.bill(id), params )
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const activeHtml = (row) => {
    const isExported = row.original.attributes?.exported
    const label = isExported ? t('true') : t('false')

    return (
      <ToggleSwitch
        handleToggle={updateCombination}
        attribute={"exported"}
        active={isExported}
        id={row.original.id}
        label={label}
      />
    )
  }

  const billRow = (row) => {
    return (
      <TableButton
        type="button"
        target="_blank"
        href={`/bills/${row.row?.original?.attributes?.id}`}
        title={row.row?.original?.attributes?.name}
        className="btn-light-warning"
      />
    )
  }

  const dateRow = (row) => {
    return (
      <span>
        {isInternal && `${t('dunning.ueber_dunnings.date_of_invoice')} `}
        { row.row?.original?.attributes?.bill_date }
      </span>
    )
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: 'invoice',
        size: 20,
        minSize: 20,
        header: t("invoice"),
        accessorFn: row => row?.attributes?.name,
        cell: row => billRow(row),
        enableSorting: false,
        footer: t('total')

      },
      {
        id: 'date',
        header: t('shared.date'),
        accessorFn: row => row?.attributes?.bill_date,
        cell: row => dateRow(row),
        enableSorting: false
      },
      {
        id: 'customer_n',
        header: t('shared.customer_no'),
        accessorFn: row => row?.attributes?.client_specific_number,
        cell: row => row.getValue(),
        enableSorting: false
      },
      {
        id: 'customer',
        header: t('shared.customer'),
        accessorFn: row => row?.attributes?.customer_name,
        cell: row => row.getValue(),
        enableSorting: false
      },
      {
        id: 'net',
        header: t('net'),
        accessorFn: row => row?.attributes?.net,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_row?.net_sum,
        enableSorting: false
      },
      {
        id: 'gross',
        header: t('shared.gross'),
        accessorFn: row => row?.attributes?.gross,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_row?.gross_sum,
        enableSorting: false
      },
      {
        id: 'open_amount',
        header: t('open_amount'),
        accessorFn: row => row?.attributes?.open_amount,
        cell: row => amountRow(row),
        footer: data => data?.table?.options?.meta?.total_row?.open_sum,
        enableSorting: false
      },
    ];

    const exportedColumn = currentCustomizing.show_export_flag ?
      [
        {
          id: 'exported',
          accessorFn: row => row?.attributes?.exported,
          header: t('shared.exported_question'),
          size: 80,
          minSize: 80,
          cell: ({ row }) => {
            return activeHtml(row)
          },
          enableSorting: false
        }
      ] : []

    return [...baseColumns, ...exportedColumn]
  }, [i18n.language])

  return (
    <BaseTable
      columns={columns}
      getDataFn={getBillStatistic}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
      filters={filters}
      showSearchInput={false}
      trigger={trigger + year + month + cutoffDate}
    />
  )
}

export default Table;
