import React from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import { LeftSquareArrowIcon, RightSquareArrowIcon } from "../../Icons";

const MonthYearSelector = ({ month, year, currentDate, setYearMonth }) => {
  const { t } = useTranslation();

  function formatDateString(year, month) {
    const monthIndex = parseInt(month, 10);
    const months = [
      t('months.january'),
      t('months.february'),
      t('months.march'),
      t('months.april'),
      t('months.may'),
      t('months.june'),
      t('months.july'),
      t('months.august'),
      t('months.september'),
      t('months.october'),
      t('months.november'),
      t('months.december')
    ];

    return `${months[monthIndex]} ${year}`;
  }

  const getMonths = () => {
    return new Promise((resolve) => {
      const months = [];
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      for (let i = -24; i <= 4; i++) {
        const monthOffset = i;
        const month = ((currentMonth + monthOffset) % 12 + 12) % 12;
        const year = currentYear + Math.floor((currentMonth + monthOffset) / 12);
        months.push({
          label: formatDateString(year, month),
          year,
          month,
        });
      }

      months.sort((a, b) => {
        if (a.year === b.year) {
          return b.month - a.month;
        }
        return b.year - a.year;
      });


      resolve(months);
    });
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setYearMonth({ month: selectedOption.month + 1, year: selectedOption.year })
    }
  };

  const handleArrowClick = (direction) => {
    let newMonth = parseInt(month, 10) + direction;
    let newYear = parseInt(year, 10);

    if (newMonth > 12) {
      newMonth = 1;
      newYear++;
    }
    else if (newMonth < 1) {
      newMonth = 12;
      newYear--;
    }

    handleSelectChange({ month: newMonth - 1, year: newYear });
  };

  return (
    <div className='d-flex justify-content-between mb-6'>
      <div onClick={() => handleArrowClick(-1)} role="button">
        <LeftSquareArrowIcon className={"fs-4x text-warning ms-6"} />
      </div>
      <AsyncSelectAdapter
        key={`${month}-${year}`}
        input={{ onChange: handleSelectChange }}
        loadOptions={getMonths}
        placeholder={formatDateString(year, month - 1)}
        className={"fw-bold fs-1 text-black"}
        placeholderStyle={{ color: 'black' }}
        menuList={{ fontSize: '1rem' }}
      />
      <div onClick={() => handleArrowClick(1)} role="button">
        <RightSquareArrowIcon className={"fs-4x text-warning ms-6"} />
      </div>
    </div>
  );
};

export default MonthYearSelector;
