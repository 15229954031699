import React from 'react';
import Table from './Table';

const StatisticsOfService = ({ business_partner_id, company_id, service_id, order_id, bill_id }) => {
  const tableToolbar = () => {
    return (<></>)
  }

  return (
    <Table
      business_partner_id={business_partner_id}
      company_id={company_id}
      service_id={service_id}
      order_id={order_id}
      bill_id={bill_id}
      tableToolbar={tableToolbar}
    />
  )
}

export default StatisticsOfService;
