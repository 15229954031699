import CustomizingModal from '../../../pages/Articles/EditCustomizingModal';
import Modal from '../../Modal';
import React, { useState } from 'react';
import TableButton from '../../Table/TableButton';
import { GearIcon } from '../../Icons';
import { useTranslation } from 'react-i18next';

const SettingsPanel = ({ plant_protection }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="d-flex flex-stack mb-4">
      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
          {t('view_article')}
        </h1>
      </div>
      <div className="d-flex align-items-center gap-2 gap-lg-3">
        <div className="d-flex align-items-center gap-3">
          <TableButton
            type="button"
            href="/plant_protection_products"
            className="btn-light-warning py-2"
            title={t('plant_protection')}
          />
          <TableButton
            type="button"
            className="btn-light-warning py-2"
            icon={<GearIcon />}
            onClick={() => setModalOpen(true)}
          />
          <Modal className="modal fade show w-750px mw-750px" open={modalOpen} onClose={() => setModalOpen(false)} title={t('stock_settings')}>
            <CustomizingModal plantProtection={plant_protection}/>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
