import React from 'react';
import EditableTextInput from '../../components/Table/EditableTextInput';
import { useTranslation } from 'react-i18next';
import EditableSelectInput from "../../components/Table/EditableSelectInput";
import { axiosInstance, routes } from '../../utils/api_base';

const sideBar = ({ siteDetails, updateSite }) => {
  const { t } = useTranslation()

  const getBusinesPartners = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      active: true
    }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        let options = response.data.data.map((bp) => ({
          value: bp.attributes.id,
          label: bp.attributes.name,
        }))
        options.push({
          value: '',
          label: t('no_selection')
        })
        return options
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <>
      {siteDetails &&
      (<div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
        <div className="card mb-5 mb-xl-8 top-0 position-md-sticky">
          <div className="card-header ribbon ribbon-end ribbon-clip">
            <span className="align-content-center fs-3 text-gray-800 fw-bold">
              ({siteDetails.client_specific_number})
              <span className='ms-2'>{siteDetails.name}</span>
            </span>
          </div>
          <div className="card-body">
            <div className="d-flex flex-stack fs-4 py-3">
              <div className="fw-bold" >
                {t('details')}
              </div>
            </div>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4 flex-stack'>
              <div className="fw-bold align-items-center d-flex">
                {t("name")}
              </div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={siteDetails.id}
                  attribute="name"
                  placeholder={t("name")}
                  initialValue={siteDetails.name}
                  handleSubmit={updateSite}
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4 flex-stack'>
              <div className="fw-bold align-items-center d-flex">
                {t('own_project_number')}
              </div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={siteDetails.id}
                  attribute="client_specific_number"
                  placeholder={t('own_project_number')}
                  initialValue={siteDetails.client_specific_number}
                  handleSubmit={updateSite}
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4 flex-stack'>
              <div className="fw-bold align-items-center d-flex">
                {t('external_number')}
              </div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={siteDetails.id}
                  attribute="external_number"
                  placeholder="external_number"
                  initialValue={siteDetails.external_number}
                  handleSubmit={updateSite}
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className='d-flex justify-content-between py-4'>
              <div className="fw-bold align-content-center">
                {t('shared.customer')}
              </div>
              <div className="text-gray-600">
                <EditableSelectInput
                  id={siteDetails.id}
                  attribute="business_partner"
                  placeholder={t('shared.customer')}
                  returnObject
                  initialValue={{ value: siteDetails.business_partner_id, label: siteDetails.business_partner_original_name }}
                  handleSubmit={updateSite}
                  getEntities={getBusinesPartners}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>)
    }
    </>

  );
};

export default sideBar;
