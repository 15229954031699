import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import Table from './Table';
import { ExportIcon } from '../../components/Icons';
import DatePicker from '../../components/DatePicker';
import Dropdown from 'react-bootstrap/Dropdown'

const BillStatistics = ({ filters, show_cutoff_date_status_id }) => {
  const { t, i18n } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search)
  const month = urlParams.get("month") ?? (new Date().getMonth() + 1).toString().padStart(2, "0")
  const year = urlParams.get("year") ?? (new Date()).getFullYear()
  const status = urlParams.get("status")
  const [selectedDate, setSelectedDate] = useState({ year: year, month: month })
  const [cutoffDate, setCutoffDate] = useState(urlParams.get("cutoff_date"))

  const [showCutoffDate, setShowCutoffDate] = useState(urlParams.get("status") == show_cutoff_date_status_id);

  const onChangeDatePicker = (selectedDates, dateStr) => {
    const [month, year] = i18n.language === "de" ? dateStr.split(".") : dateStr.split("-")
    setSelectedDate({ year: year, month: month })
  }

  const onChangeCutoffDate = (_, dateStr) => {
    setCutoffDate(dateStr)
  }

  const paramsForExport = () => {
    const params = new URLSearchParams();

    if (month) params.append("month", month);
    if (year) params.append("year", year);
    if (status && status != 'All') params.append("status", status)

    return params.toString();
  }

  const tableToolbar = () => {
    return (
      <>
        <Dropdown align={"end"} className="d-inline me-5" id="main-dropdown">
          <Dropdown.Toggle className="btn-light-warning" id="custom-dropdown-autoclose-outside">
            {t('shared.export')}
          </Dropdown.Toggle>
          <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-warning fw-semibold py-4 w-250px fs-6'>
            <div className="menu-item px-5">
              <a href={`/statistics/bills.xlsx?${paramsForExport()}`} target='_blank' className="menu-link px-5">{t('download_excel')}</a>
            </div>
            <div className="separator border-gray-200" />
            <div className="menu-item px-5">
               <a href={`/statistics/bills.pdf?${paramsForExport()}`} target='_blank' className="menu-link px-5">{t('create_pdf')}</a>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <DatePicker monthOnly options={{
          defaultDate: `${selectedDate.month}-${selectedDate.year}`,
          dateFormat: i18n.language === "de" ? "m.Y" : "m-Y",
          onChange: onChangeDatePicker
        }} />
        {
          showCutoffDate &&
            <DatePicker
              placeholder={t("cutoff_date")}
              className="form-control-solid ms-4"
              options={{
                defaultDate: cutoffDate,
                dateFormat: i18n.language === "de" ? "d.m.Y" : "d-m-Y",
                onChange: onChangeCutoffDate
              }}
            />
        }
      </>
    )
  }

  return (
    <>
      <Table
        tableToolbar={tableToolbar}
        filters={filters}
        year={selectedDate.year}
        month={selectedDate.month}
        cutoffDate={cutoffDate}
        setShowCutoffDate={setShowCutoffDate}
        show_cutoff_date_status_id={show_cutoff_date_status_id}
      />
    </>
  )
}

export default BillStatistics;
