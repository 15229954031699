import React, { useState }from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Create from './Create';
import Modal from '../Modal';

const PurchaseBills = ({ filters }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false)
  const [trigger, setTrigger] = useState(false)

  const tableToolbar = () => {
    return (
      <>
        <TableButton href={'/purchase_bills/priceless'} type="button" className="btn-light-primary me-3" title={t('entrances_without_price')} />
        <TableButton className="btn-primary me-3" onClick={() => setModalOpen(true)} icon={<PlusIcon />} title={t('new_goods_receipt')} />
      </>
    )
  }

  return (
    <>
      <Modal className="modal fade show w-300" open={modalOpen} onClose={() => setModalOpen(false)} title={t('new_goods_receipt')}>
        <Create setTrigger={setTrigger} onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} filters={filters} trigger={trigger} />
    </>
  )
}

export default PurchaseBills;
