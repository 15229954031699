import React from 'react'
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

const AsyncSelectAdapter = ({
  input,
  className,
  placeholderStyle = {},
  selectedValueStyle = {},
  menuList = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const customStyles = {
    // Customize the placeholder text
    placeholder: (provided) => ({
      ...provided,
      ...placeholderStyle,
    }),
    // Customize the selected option text
    singleValue: (provided) => ({
      ...provided,
      ...selectedValueStyle,
    }),
    menuList: (provided) => ({
      ...provided,
      ...menuList,
    })
  };
  return (
    <AsyncSelect
      {...input}
      cacheOptions
      defaultOptions
      styles={customStyles}
      {...rest}
      className={className}
      placeholder={t("please_select")}
    />
  )
}

export default AsyncSelectAdapter
