import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import { TrashSquareIcon } from '../../components/Icons';
import EditableTextInput from '../../components/Table/EditableTextInput';
import Toastr from '../../components/Toastr';

const Table = ({ tableToolbar, trigger, setTrigger }) => {
  const { t, i18n } = useTranslation()

  const getDieselPrices = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    let params = { page: pageIndex + 1, limit: pageSize }

    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.dieselPrices(), { params: params })

    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const deleteDieselPrice = (id) => {
    axiosInstance.delete(routes.dieselPrice(id))
      .then(resp => {
        Toastr({ message: t('delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000))
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const updateDieselPrice = (id, params) => {
    let data = { diesel_price: {price: params.price}}
    axiosInstance.put(routes.dieselPrice(id), data)
      .then(resp => {
        Toastr({ message: t("update_success"), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000))
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      <div className={'cursor-pointer'} onClick={() => deleteDieselPrice(row.original.id)}>
        <TrashSquareIcon className="fs-2hx text-danger" />
      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'valid_from',
        size: 20,
        minSize: 20,
        enableSorting: false,
        header: t('valid_from'),
        accessorFn: row => row?.attributes?.valid_from,
        cell: row => row.getValue()
      },
      {
        id: 'prices',
        header: t('prices'),
        enableSorting: false,
        accessorFn: row => row?.attributes?.price,
        cell: ({ row }) => {
          return (
            <div className='d-flex align-items-center'>
              <EditableTextInput
                id={row?.original?.id}
                attribute='price'
                initialValue={row?.original?.attributes?.price || "-"}
                handleSubmit={updateDieselPrice}
              />
              <span className="ms-2">{t('per_liter')}</span>
            </div>
          )
        }
      },
      {
        id: 'delete',
        header: null,
        size: 50,
        minSize: 50,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getDieselPrices}
      tableToolbar={tableToolbar}
      showSearchInput={false}
      trigger={trigger}
    />
  )
}

export default Table;
