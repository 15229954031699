import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../Table';
import TableButton from '../Table/TableButton';
import { isEmpty } from 'lodash';

const Table = ({ filters, tableToolbar, rental = false }) => {
  const { t, i18n } = useTranslation()

  const getMachines = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.machines(), { params: { ...params, rental: rental, serialize: 'full' } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.machine_link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'machine_group',
        accessorFn: row => row?.attributes?.group_name,
        header: t('shared.machine_group'),
        cell: row => row.getValue(),
      },
      {
        id: 'name',
        accessorFn: row => row?.attributes?.db_name,
        header: t('shared.name'),
        cell: row => row.getValue(),
      },
      {
        id: 'target_hours',
        accessorFn: row => row?.attributes?.hours_plan,
        header: t('shared.target_hours'),
        cell: row => row.getValue(),
      },
      {
        id: 'actual_hours',
        accessorFn: row => row?.attributes?.hours_actual,
        header: t('shared.actual_hours'),
        cell: row => row.getValue(),
      },
      {
        id: 'cost_rate_per_hour',
        accessorFn: row => row?.attributes?.rate,
        header: t('machines_table.per_hour'),
        cell: row => row.getValue(),
      },
      {
        id: 'license_plate',
        accessorFn: row => row?.attributes?.license_plate,
        header: t('machines_table.registration_plate'),
        cell: row => row.getValue(),
      },
      {
        id: 'location',
        accessorFn: row => row?.attributes?.location_name,
        header: t('shared.location'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getMachines}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
    />
  )
}

export default Table
