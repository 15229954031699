import React, { useState } from 'react'
import TableButton from '../../components/Table/TableButton';
import Toastr from '../../components/Toastr';
import { ArrowsCircleIcon, CloudDownloadIcon } from '../../components/Icons';
import { PencilIcon } from '../../components/Icons';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../../components/ToggleSwitch';

const EditCustomizingModal = ({ onClose, plantProtection }) => {
  const { t } = useTranslation()
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const [customizing, setCustomizing] = useState(currentCustomizing);

  const handleLinkClick = (event) => {
    event.preventDefault();
    if (window.confirm(textForPlantProtection('question'))) {
      axiosInstance
        .put(routes.articlesLoadPlantProtectionProducts())
        .then(response => {
          Toastr({
            message: response.data.notice,
            options: { closeButton: true }
          })
          window.location = response.data.redirect_url
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  const toggleHtml = (attribute) => {
    const isActive = customizing[attribute]
    const label = isActive ? t('true') : t('false')

    return (
      <ToggleSwitch
        id={attribute}
        handleToggle={() => updateCustomizing(currentCustomizing?.id, { [attribute]: !isActive })}
        attribute={attribute}
        active={isActive}
        className="ps-0"
        label={label}
      />
    )
  }

    const updateCustomizing = (id, attrs) => {
      const params = {
        customizing: {
          show_article_tab_in_order: attrs.show_article_tab_in_order
        }
      }

      axiosInstance.put(routes.customizing(id), params)
        .then(resp => {
          Toastr({ message: t('success'), options: { showDuration: 2000 } })
          appStore.dispatch({
            type: 'UPDATE_CURRENT_CUSTOMIZING',
            payload: attrs,
          });

          setCustomizing(prevState => ({
            ...prevState,
            ...attrs
          }))

        })
        .catch(error => {
          Toastr({
            type: 'error',
            message: error.response?.data?.error || t('critical_error')
          })
        })
    }

  const textForPlantProtection = (key) => {
    const texts = {
      title: plantProtection ? t('update_pesticides') : t('load_crop_production_into_storage'),
      question: plantProtection ? t('update_pesticides_confirm_question') : t('load_crop_production_into_storage_confirm_question'),
    }

    return texts[key]
  }

  return (
    <>
      <div className="modal-body">
        <div className="row mb-3">
          <div className="col">
            <span className='text-gray-600'>{t('article_groups')}</span>
          </div>
          <div className="col">
            <TableButton
              href='/article_groups'
              className="btn-light-primary text-start"
              icon={<PencilIcon />}
              title={t('edit_article_groups')}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <span className='text-gray-600'>{t('units_word')}</span>
          </div>
          <div className="col">
            <TableButton
              href='/article_groups'
              className="btn-light-primary text-start"
              icon={<PencilIcon />}
              title={t('edit_units')}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <span className='text-gray-600'>{t('show_article')}</span>
          </div>
          <div className="col">
            {toggleHtml('show_article_tab_in_order')}
          </div>
          <div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <span className='text-gray-600'>{t('plant_protection')}</span>
          </div>
          <div className="col">
            <TableButton
              className="btn-light-primary text-start"
              rel="nofollow"
              onClick={handleLinkClick}
              title={textForPlantProtection('title')}
              icon={plantProtection ? <ArrowsCircleIcon className='fs-2' /> : <CloudDownloadIcon className='fs-2' />}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <TableButton
          className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
          type="button"
          onClick={onClose}
          title={t('cancel')}
        />
      </div>
    </>
  )
}

export default EditCustomizingModal;
