import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import EditableTextInput from "../Table/EditableTextInput";
import Toastr from '../Toastr';

const CurrentDieselPrice = ({ diesel_price_id, diesel_price }) => {
  const { t } = useTranslation()
  const [currentDieselPrice, setCurrentDieselPrice] = useState(diesel_price || "-")

  const updateDieselPrice = (id, params) => {
    let data = { diesel_price: { price: params.diesel_price }}

    axiosInstance.put(routes.dieselPrice(id), data)
      .then(resp => {
        Toastr({ message: t("update_success"), options: { showDuration: 2000 } })
        setCurrentDieselPrice(params.diesel_price);
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  return (
    <div className="d-flex align-items-center">
      <a href="diesel_prices" className="link-primary">
        {t("current_diesel_price")}:
      </a>
      <div className="ms-2">
        <EditableTextInput
          id={diesel_price_id}
          attribute="diesel_price"
          initialValue={currentDieselPrice || "-"}
          handleSubmit={updateDieselPrice}
        />
      </div>
    </div>
  )
};

export default CurrentDieselPrice;
