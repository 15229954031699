
import React, { useEffect } from "react";
import EditableTextInput from "../Table/EditableTextInput";
import { Tooltip } from 'bootstrap'
import QuestionMark from '../Icons/QuestionMark';
import { useTranslation } from 'react-i18next';

const StaffTextInput = ({ staffId, initialValue, translation, updateStaff, attribute, tooltipText }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, []);

  const AttributeTooltip = () => {
    return (
      attribute === "comment" ? <span
        data-bs-toggle="tooltip"
        data-bs-custom-class="tooltip-inverse"
        data-bs-placement="top"
        title={tooltipText}
        className='btn btn-sm p-2'
      >
        <QuestionMark />
      </span> : <></>
    )
  };

  return (
    <>
      <div className="fw-bold">
        {translation}
        <AttributeTooltip />
      </div>
      <div className="text-gray-600">
        <EditableTextInput
          id={staffId}
          attribute={attribute}
          initialValue={initialValue || "-"}
          handleSubmit={updateStaff}
          placeholder={translation}
        />
      </div>
      <span className="separator my-5"></span>
    </>
  );
}

export default StaffTextInput;
