import React, { useState, useEffect } from 'react';
import DetailSideBar from '../../components/Sites/sidebar';
import OrdersTable from '../../components/Sites/OrdersTable/Table';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import EditableDatePickerInput from '../../components/Table/EditableDatePickerInput';
import EditableTextInput from '../../components/Table/EditableTextInput';

const Site = ({ id }) => {
  const [siteDetails, setSiteDetails] = useState()
  const { t } = useTranslation()

  const getSite = (id) => {
    axiosInstance.get(routes.site(id))
      .then((response) => {
        setSiteDetails(prevState => ({
          ...prevState,
          ...response.data.data.attributes
        }))
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  }

  const updateSite = (id, attrs) => {
    const params = {
      site: {
        client_specific_number: attrs.client_specific_number,
        name: attrs.name,
        bill_address_name: attrs.bill_address_name,
        external_number: attrs.external_number,
        business_partner_id: attrs.business_partner?.value,
        bill_address_street: attrs.bill_address_street,
        bill_address_postal_code: attrs.bill_address_postal_code,
        bill_address_city: attrs.bill_address_city,
        site_street: attrs.site_street,
        site_postal_code: attrs.site_postal_code,
        site_city: attrs.site_city,
        start_date: attrs.start_date,
        end_date: attrs.end_date

      }
    }

    axiosInstance.put(routes.site(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })

        setSiteDetails((prevDetails) => ({
          ...prevDetails,
          ...resp.data.data.attributes,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  useEffect(() => {
    getSite(id)
  }, [id])

  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <DetailSideBar siteDetails={siteDetails} updateSite={updateSite}/>
        <div className='flex-lg-row-fluid ms-lg-15'>
          {siteDetails?.id && (
            <>
              <div className="d-flex row">
                <div className="col-12 col-lg-6">
                  <div className="card mb-5 mb-xl-8">
                    <div className="card-body pt-2">
                      <div className="py-2 fs-6">
                        <div className="fw-bold mb-5" >
                          {t('billing_address')}
                        </div>
                        <div className="separator separator-dashed my-3"></div>
                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">Name</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="bill_address_name"
                              placeholder='bill_address_name'
                              initialValue={siteDetails.bill_address_name}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>
                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('street')}</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="bill_address_street"
                              placeholder={t('street')}
                              initialValue={siteDetails.bill_address_street}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>

                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('postcode')}</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="bill_address_postal_code"
                              placeholder={t('postcode')}
                              initialValue={siteDetails.bill_address_postal_code}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>

                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('city')}</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="bill_address_city"
                              placeholder={t('city')}
                              initialValue={siteDetails.bill_address_city}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="card mb-5 mb-xl-8">
                    <div className="card-body pt-2">
                      <div className="py-2 fs-6">
                        <div className="fw-bold mb-5" >
                          {t('location')}
                        </div>
                        <div className="separator separator-dashed my-3"></div>
                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('street')}</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="site_street"
                              placeholder={t('street')}
                              initialValue={siteDetails.site_street}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>

                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('postcode')}</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="site_postal_code"
                              placeholder={t('postcode')}
                              initialValue={siteDetails.site_postal_code}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>
                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('city')}</div>
                          <div className="text-gray-600">
                            <EditableTextInput
                              id={siteDetails.id}
                              attribute="bill_address_city"
                              placeholder={t('city')}
                              initialValue={siteDetails.bill_address_city}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>
                        <div className='d-flex flex-stack'>
                          <div className="fw-bold ">{t('shared.period')}</div>
                          <div className="text-gray-600 d-flex">
                            <EditableDatePickerInput
                              id={siteDetails.id}
                              attribute='start_date'
                              initialValue={siteDetails.start_date || "2000-01-01"}
                              handleSubmit={updateSite}
                            />
                            <span className="mx-3">-</span>
                            <EditableDatePickerInput
                              id={siteDetails.id}
                              attribute='end_date'
                              initialValue={siteDetails.end_date || "2000-01-01"}
                              handleSubmit={updateSite}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold">
              {t('orders')}
            </div>
          </div>
          <OrdersTable siteId={id}/>
        </div>
      </div>
    </>
  )
}

export default Site;
