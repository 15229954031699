import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, articleId }) => {
  const { t, i18n } = useTranslation()
  const getArticleGroups = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const urlParams = new URLSearchParams(window.location.search)
    const tabName = { tab: urlParams.get("tab") }

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.articleConsumptions(), { params: { ...params, article_id: articleId } })
    params = { ...params, ...tabName }

    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const orderRow = (row) => {
    return (
      <a href={row?.original?.attributes?.order_link}>
        {row?.original?.attributes?.order_name}
      </a>
    )
  }

  const staffRow = (row) => {
    return (
      <a href={row?.original?.attributes?.staff_link}>
        {row?.original?.attributes?.staff_name}
      </a>
    )
  }

  const machineRow = (row) => {
    return (
      <a href={row?.original?.attributes?.machine_link}>
        {row?.original?.attributes?.machine_name}
      </a>
    )
  }
  const columns = useMemo(
    () => [
      {
        id: 'day',
        header: t('time_expressions.day'),
        accessorFn: row => row?.attributes?.date,
        cell: row => row.getValue()
      },
      {
        id: 'order',
        accessorFn: row => row?.attributes?.order_name,
        header: t('shared.order'),
        cell: ({ row }) => orderRow(row)
      },
      {
        id: 'staff',
        accessorFn: row => row?.attributes?.staff_name,
        header: t('shared.employees'),
        cell: ({ row }) => staffRow(row)
      },
      {
        id: 'machine',
        accessorFn: row => row?.attributes?.machine_name,
        header: t('vehicle'),
        cell: ({ row }) => machineRow(row)
      },
      {
        id: 'quantity',
        accessorFn: row => row?.attributes?.quantity,
        header: t('shared.quantity'),
        cell: row => row.getValue()
      },
      {
        id: 'costs',
        accessorFn: row => row?.attributes?.cost,
        header: t('shared.costs'),
        cell: row => row.getValue()
      },
      {
        id: 'comment',
        accessorFn: row => row?.attributes?.comment,
        header: t('shared.comment'),
        cell: row => row.getValue()
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getArticleGroups}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
