import BaseTable from '../../Table';
import React, { useMemo, Fragment } from 'react';
import StatusLabel, { statusCodeStyle } from '../../../core/StatusLabel';
import TableButton from '../../Table/TableButton';
import classNames from 'classnames';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { PhoneIcon } from '../../Icons';

const OrderTable = ({ siteId }) => {
  const { t, i18n } = useTranslation()

  const OrdersData = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const params = { page: pageIndex + 1, limit: 3, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.orders(), { params: { ...params, site_id: siteId } })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    const icon = row?.original?.attributes?.mobile ? <PhoneIcon /> : null

    return (
      <>
        <TableButton
          icon={icon}
          target="_blank"
          href={row?.original?.attributes?.link}
          className="btn-light-warning me-3"
          title={row?.original?.attributes?.id}
        />
        {row?.original?.attributes?.bills.map((bill, index) => {
          return billHtml(bill, index)
        })}
      </>
    )
  }

  const billHtml = (bill, index) => {
    return (
      <Fragment key={index}>
        <br />
        <a target="_blank" href={bill.attributes.link}>
          <span
            className={classNames('badge', statusCodeStyle(bill.attributes.status_code?.toLowerCase()))}
          >
            {bill.attributes.name}
          </span>
        </a>
      </Fragment>
    )
  }

  const tableToolbar = () => {
    return (
      <></>
    )
  }

  const orderColumns = useMemo(
    () => [
      {
        id: 'code',
        header: "ID",
        accessorFn: row => row?.attributes?.id,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.customer'),
        cell: row => row.getValue(),
      },
      {
        id: 'comment',
        header: t('shared.comment'),
        accessorKey: 'attributes.comment',
        cell: row => row.getValue(),
      },
      {
        id: 'status',
        accessorKey: 'attributes.status_code',
        header: t('shared.status'),
        cell: ({ row }) => {
          return <StatusLabel code={row.original.attributes?.status_code?.toLowerCase()} />
        },
        size: 80,
        minSize: 80,
      },
      {
        id: 'period',
        header: t('shared.period'),
        accessorKey: 'attributes.order_dates',
        cell: row => row.getValue(),
        size: 80,
        minSize: 80,
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={orderColumns}
      columnVisibility={{}}
      getDataFn={OrdersData}
      tableToolbar={tableToolbar}
      tableName={'Orders'}
    />
  )
}

export default OrderTable;
