import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ business_partner_id, company_id, service_id, order_id, bill_id, tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getServiceBusinessPartnerStatistics = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = {
      page: pageIndex + 1, limit: pageSize, sort: sortQuery[0],
      service_id: service_id,
      company_id: company_id,
      order_id: order_id,
      bill_id: bill_id,
      business_partner_id: business_partner_id
    }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.serviceCustomerStatistics(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count, meta: res.data.meta }
    )
  }

  const formatQuantityProvided = (quantityProvided) => {
    return (
      <>
        {quantityProvided.map((quantity, index) => (
          <React.Fragment key={index}>
            <small>
              <u>{quantity[0]}:</u> {quantity[1]} {quantity[2]}
            </small>
            <br />
          </React.Fragment>
        ))}
      </>
    )
  };

  const formatLinks = (links) => {
    return (
      <>
        {Object.keys(links).map((key) => (
          <React.Fragment key={key}>
            <a href={links[key].entity_link}>{key}</a>
            {
              links[key].show_ban ?
                <span className="fa ps-2">
                  <i className="fas fa-ban position-absolute text-danger"></i>
                  <i className="fas fa-filter"></i>
                </span>
                :
                <a href={links[key].funnel_link} className="ps-2">
                  <i className="fas fa-filter"></i>
                </a>
              }
          </React.Fragment>
        ))}
      </>
    )
  };

  const columns = useMemo(
    () => [
      {
        id: 'formatted_date',
        accessorFn: row => row?.attributes?.formatted_date,
        header: t('date'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: t('total')
      },
      {
        id: 'quantity_invoiced',
        accessorFn: row => row?.attributes?.quantity_invoiced,
        header: t('quantity_billed'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'quantity_provided',
        accessorFn: row => formatQuantityProvided(row?.attributes?.quantity_provided),
        header: t('quantity_done'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'revenue',
        accessorFn: row => row?.attributes?.revenue,
        header: t('sales_volume'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_revenue
      },
      {
        id: 'costs',
        accessorFn: row => row?.attributes?.costs,
        header: t('costs'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_costs
      },
      {
        id: 'profit_loss_value',
        accessorFn: row => row?.attributes?.profit_loss_value,
        header: t('profit_loss'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_profit_loss_value
      },
      {
        id: 'invoices',
        accessorFn: row => formatLinks(row?.attributes?.invoice_links),
        header: t('invoices'),
        enableSorting: true,
        cell: row => row.getValue()
      },
      {
        id: 'credit_notes',
        accessorFn: row => formatLinks(row?.attributes?.credit_note_links),
        header: t('credit_note'),
        enableSorting: true,
        cell: row => row.getValue()
      },
      {
        id: 'orders',
        accessorFn: row => formatLinks(row?.attributes?.order_links),
        header: t('orders'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => <svg width='16' height='16' style={{ verticalAlign: 'middle', display: 'block', margin: '0 auto' }}>
          <circle cx='8' cy='8' r='8' fill={data?.table?.options?.meta?.totals_chart_color} />
        </svg>
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getServiceBusinessPartnerStatistics}
      columns={columns}
      tableToolbar={tableToolbar}
      enableFooter={true}
    />
  )
}

export default Table;
