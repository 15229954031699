import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import { isRequired } from '../Form/validations';
import TableButton from '../Table/TableButton';
import ErrorField from '../Form/ErrorField';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import { Tooltip } from 'bootstrap'
import debounce from "lodash/debounce";
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'
import moment from "moment";
import DatePicker from '../../components/DatePicker';

const Create = ({ onClose, setTrigger }) => {
  const { t, i18n } = useTranslation();
  const currentDate = moment().toDate();

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [onClose]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const onSubmit = async values => {
    const data = {
      purchase_bill: {
        comment: values.comment,
        date: Array.isArray(values.date) ? values.date[0] : values.date,
        business_partner_id: values.businessPartnerId?.value,
        bill_number: values.billNumber,
        payment_day: Array.isArray(values.paymentDay) ? values.paymentDay[0] : values.paymentDay
      }
    };

    return axiosInstance.post(routes.purchaseBills(), data)
      .then(response => {
        Toastr({
          title: t('shared.success'),
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        setTrigger(Math.floor(Date.now() / 1000))
      }).catch(error => {

        const errorData = error.response.data
        if (errorData.error) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.long_name,
      }))
      )
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadCustomers = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'code', query: inputText, active: true, vendor: true };
    return fetchData(routes.businessPartners(), params);
  });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        comment: '',
        date: currentDate,
        paymentDay: ''
      }}
      validate={values => {
        let errors = {}
        errors.businessPartnerId = isRequired(values.businessPartnerId)
        errors.billNumber = isRequired(values.billNumber)
        errors.date = isRequired(values.date)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="container">
              <div className="row mb-3">
                <div className="col">
                  <label>{t('dunning.ueber_dunnings.date_of_invoice')}</label>
                  <Field
                    name="date"
                    render={({ input }) => (
                      <DatePicker
                        options={{
                          defaultDate: currentDate,
                          dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'd-m-Y'),
                          onChange: input.onChange
                        }}
                      />
                    )}
                  />
                  <ErrorField name="date" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('supplier')}</label>
                  <Field
                    name='businessPartnerId'
                    component={AsyncSelectAdapter}
                    loadOptions={loadCustomers}
                    placeholder={t('select')}
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="businessPartnerId" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('invoice_number')}</label>
                  <Field
                    name="billNumber"
                    component="input"
                    type="text"
                    className="form-control"
                    autoFocus
                  />
                  <ErrorField name="billNumber" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('due_date')}</label>
                  <Field
                    name="paymentDay"
                    render={({ input }) => (
                      <DatePicker
                        options={{
                          dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'd-m-Y'),
                          onChange: input.onChange
                        }}
                      />
                    )}
                  />
                  <ErrorField name="paymentDay" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('comment')}</label>
                  <Field
                    name="comment"
                    component="input"
                    type="text"
                    className="form-control"
                    autoFocus
                  />
                  <ErrorField name="comment" />
                </div>
              </div>

              {submitError && <div className="form-error-message">{submitError}</div>}
              <div className="modal-footer">
                <TableButton
                  className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                  type="button"
                  onClick={onClose}
                  title={t('cancel')}
                />
                <TableButton
                  className="btn btn-primary fw-semibold px-6"
                  type="submit"
                  disabled={submitting || pristine}
                  title={t('create')}
                />
              </div>
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create;
