import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';
import DetailSideBar from '../../components/Articles/Details/sidebar';
import { pushToHistory } from '../../utils/base';
import SettingsPanel from '../../components/Articles//Details/SettingsPanel';
import ArticlePurchases from '../ArticlePurchases/Table';
import ArticleShowDisposed from '../ArticleShowDisposed/Table';
import ArticleConsumptions from '../ArticleConsumptions/Table';
import ArticleBills from '../ArticleBills/Table';
import MonthlyDieselConsumption from '../../components/MonthlyDieselConsumption';
import { axiosInstance, routes } from '../../utils/api_base';

const Article = ({ id, plant_protection, enableHaakDieselExport, startDate, endDate, }) => {
  const { t } = useTranslation()
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.tab || 'purchases')
  const [article, setArticle] = useState({});

  useEffect(() => {
    pushToHistory({ tab: activeTab })
  }, [activeTab])

  useEffect(() => {
    getArticle(id)
  }, [id])

  const getArticle = (id) => {
    axiosInstance.get(routes.article(id))
      .then((response) => {
        storeState(response)
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  }

  const storeState = (response) => {
    setArticle(prevState => ({
      ...prevState,
      ...response.data.data.attributes
    }))
  }

  const tableToolbar = () => {
    return (
      <></>
    )
  }

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <DetailSideBar articleId={id} article={article} storeState={storeState}/>
      <div className='flex-lg-row-fluid ms-lg-15'>
        <>
          <SettingsPanel plant_protection={plant_protection} />
          <Tabs
            onSelect={(k) => {
              setActiveTab(k)
              pushToHistory({ tab: k })
            }}
            defaultActiveKey={activeTab}
            id="article-tabs"
            className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
          >
            <Tab eventKey="purchases" title={t('purchases')} mountOnEnter={true} unmountOnExit={true}>
              <ArticlePurchases tableToolbar={tableToolbar} articleId={id} plant_protection={plant_protection} />
            </Tab>
            <Tab eventKey="planned" title={t('planned')} mountOnEnter={true} unmountOnExit={true}>
              <ArticleShowDisposed tableToolbar={tableToolbar} articleId={id} plant_protection={plant_protection} />
            </Tab>
            <Tab eventKey="consumptions" title={t('consumptions')} mountOnEnter={true} unmountOnExit={true}>
              <>
                <ArticleConsumptions tableToolbar={tableToolbar} articleId={id} plant_protection={plant_protection} />
                {(enableHaakDieselExport && article.article_detail?.attributes?.diesel) &&
                  <MonthlyDieselConsumption articleId={id} startDate={startDate} endDate={endDate}/>
                }
              </>
            </Tab>
            <Tab eventKey="sales" title={t('sales')} mountOnEnter={true} unmountOnExit={true}>
              <ArticleBills tableToolbar={tableToolbar} articleId={id} plant_protection={plant_protection} />
            </Tab>
          </Tabs>
        </>
      </div>
    </div>
  )
};

export default Article;
