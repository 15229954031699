import React, { useState, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import MonthYearSelector from "../../components/Staffs/Payroll/MonthYearSelector";
import { pushToHistory } from "../../utils/base";
import Button from "../../components/Button";
import { DownloadIcon } from '../../components/Icons';
import Table from "../../components/Table";

const EmploymentProtection = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentDate = new Date();
  const month = urlParams.get('month') || currentDate.getMonth() + 1;
  const year = urlParams.get('year') || currentDate.getFullYear();
  const { i18n, t } = useTranslation();
  const [yearMonth, setYearMonth] = useState({ year: year, month: month });
  const [extraAbsenceHeaders, setExtraAbsenceHeaders] = useState([]);
  const [extraWageHeaders, setExtraWageHeaders] = useState([]);
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};

  const getWorkingTimeEvaluations = async (pagination, sorting) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: 20, sort: sortQuery[0], month: yearMonth.month, year: yearMonth.year }

    const res = await axiosInstance.get(routes.remunerationWorkingTimeEvaluations(), { params: params })
    pushToHistory(params)
    buildExtraHeaders(res.data.data)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const buildExtraHeaders = (data) => {
    const absenceHeaders = [...new Set(
      data.flatMap(record => [
        ...(record.attributes.absence_reasons.map(reason => reason.header) || [])
      ])
    )];

    const wageHeaders = [...new Set(
      data.flatMap(record => [
        ...(record.attributes.summaries.map(summary => summary.header) || []),
      ])
    )];

    setExtraAbsenceHeaders(absenceHeaders)
    setExtraWageHeaders(wageHeaders)
  };

  const formatedDate = () => {
    const lastDay = new Date(year, month, 0).getDate();
    const date = new Date(year, month - 1, lastDay);

    return date.toLocaleDateString('en-GB').replace(/\//g, '-');
  };

  const createExport = () => {
    axiosInstance.post(routes.exports(), {
      type: 'WorkingTimeEvaluationsExport',
      export_format: 'xlsx',
      additional_params: {
        month: month,
        year: year,
        customizing_id: currentCustomizing.id
      }
    })
      .then(response => console.log('RESPONSE', response))
      .catch(error => console.log('ERROR', error))
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: 'client_specific_number',
        header: t('personal_number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 100,
        minSize: 100,
        cell: ({ row }) => {
          return <Button classNames='primary w-20'>
            <a className="text-white" href={row.original?.attributes?.staff_link}>{row.original?.attributes?.client_specific_number}</a>
          </Button>
        },
      },
      {
        id: 'name',
        accessorFn: row => row?.attributes?.staff_name,
        header: t('shared.name'),
        cell: row => row.getValue()
      },
      {
        id: 'desired_working_hours_official',
        accessorFn: row => row?.attributes?.desired_working_hours_official,
        header: t('target_working_hours_month'),
        cell: row => row.getValue()
      },
      {
        id: 'working_time',
        accessorFn: row => row?.attributes?.working_time,
        header: t('actual_working_hours_month'),
        cell: ({ row }) => {
          return (
            <div className="d-flex align-items-baseline">
              {row.original.attributes?.working_time}
              {row.original.attributes?.has_desired_working_hours_official &&
                <small className="ms-2">{row.original.attributes?.working_time_desired_working_time_relation}</small>
              }
            </div>
          )
        }
      },
      {
        id: 'break_time',
        accessorFn: row => row?.attributes?.break_time,
        header: t('booking_codes.break_time'),
        cell: row => row.getValue()
      },
      {
        id: 'settled_work_hours',
        accessorFn: row => row?.attributes?.settled_work_hours,
        header: t('remuneration_payrolls.billed_hours'),
        cell: row => row.getValue()
      }
    ];

    const absenceColumns = extraAbsenceHeaders.map((header) => ({
      id: header,
      header: t(`booking_codes.${header}`),
      accessorFn: (row) => row?.attributes?.absence_reasons.find((reason) => reason.header === header)?.cell || '',
      cell: (row) => row.getValue(),
    }));

    const totalColumn = [
      {
        id: 'working_and_paid_absence_time',
        accessorFn: row => row?.attributes?.working_and_paid_absence_time,
        header: t('total_time'),
        cell: row => row.getValue()
      },
    ]

    const wageColumns = extraWageHeaders.map((header) => ({
      id: header,
      header: header,
      accessorFn: (row) => row?.attributes?.summaries.find((summary) => summary.header === header)?.cell || '',
      cell: (row) => row.getValue(),
    }));

    const workAccountColumn = [
      {
        id: 'work_account',
        accessorFn: row => row?.attributes?.work_account,
        header: `${t('deduction_current_account')} ${t('at_the_date', { date: formatedDate() })}`,
        cell: row => row.getValue()
      }
    ]

    const specialSurchargeColumn = currentCustomizing.use_cost_bonus_payment ?
      [
        {
          id: 'bonus_payment',
          accessorFn: row => row?.attributes?.bonus_payment,
          header: t('remuneration_payrolls.special_surcharge'),
          cell: row => row.getValue()
        }
      ] : []

    return [...baseColumns, ...absenceColumns, ...totalColumn, ...wageColumns, ...workAccountColumn, ...specialSurchargeColumn];
  }, [i18n.language, extraAbsenceHeaders, extraWageHeaders])

  return (
    <>
      <h1 className="mb-6">{t('working_time_evaluation').toUpperCase()}</h1>
      <MonthYearSelector month={yearMonth.month} year={yearMonth.year} currentDate={currentDate} setYearMonth={setYearMonth} />
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#evaluations" role="button">
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div id="evaluations" className="collapse show">
        <Table
          columns={columns}
          getDataFn={getWorkingTimeEvaluations}
          tableToolbar={() => <></>}
          trigger={yearMonth}
          showHeader={false}
        />
      </div>
      <Button classNames={"success"} onClick={() => createExport()}>
        <div className="d-flex align-items-center">
          <DownloadIcon className={"text-white fs-2 me-2"} />
          {t('payroll_in_excel')}
        </div>
      </Button>
    </>
  );
};

export default EmploymentProtection;
