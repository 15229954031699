import React, { useState } from 'react';
import Table from './Table';
import Create from './Create';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { PlusIcon } from '../../components/Icons';

const dieselPrices = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const { t } = useTranslation()

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-warning me-4"
        onClick={() => setModalOpen(true)}
        icon={<PlusIcon />}
        title={t('shared.add_to')}
      />
    )
  }

  return (
    <>
      <Modal className="modal fade show modal-lg" open={modalOpen} onClose={() => setModalOpen(false)} title={t('new_diesel_price')}>
        <Create setTrigger={setTrigger} onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} trigger={trigger} setTrigger={setTrigger}/>
    </>
  );
};

export default dieselPrices;
