import React, { useMemo, useState } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, articleId }) => {
  const { t, i18n } = useTranslation()
  const [totalRow, setTotalRow] = useState([])

  const getDieselConsumptions = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex } = pagination

    let params = { page: pageIndex + 1, limit: 35, article_id: articleId }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.monthlyDieselConsumptions(), { params: params })
    setTotalRow(res.data.meta.total_consumption)

    return (
      { rows: res.data.monthly_consumption, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }
  const tableFooter = () => {
    return (
      <tr className='fw-bold'>
        <td>{t('shared.total')}</td>
        <td>{totalRow || 0} </td>
      </tr>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'month',
        size: 20,
        minSize: 20,
        enableSorting: false,
        header: t('month'),
        accessorFn: row => row.month,
        cell: row => row.getValue(),
      },
      {
        id: 'consumption',
        header: t('consumptions'),
        enableSorting: false,
        accessorFn: row => row?.consumption,
        cell: row => row.getValue()
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      showHeader={false}
      showSearchInput={false}
      trigger={articleId}
      getDataFn={getDieselConsumptions}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
      tableFooter={tableFooter()}
    />
  )
}

export default Table;
