import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';

const Table = ({ tableToolbar, articleId }) => {
  const { t, i18n } = useTranslation()

  const getArticleGroups = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const urlParams = new URLSearchParams(window.location.search)
    const tabName = { tab: urlParams.get("tab") }

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.articleBillFeatures(), {
      params: {
        ...params,
        article_id: articleId
      }
    })
    params = { ...params, ...tabName }

    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const invoiceRow = (row) => {
    return (
      <TableButton
        type="button"
        target="_blank"
        href={row?.original?.attributes?.invoice_link}
        title={row?.original?.attributes?.invoice_name}
        className="btn-light-primary"
      />
    )
  }

  const consumptionSaleRow = (row) => {
    return (
      (row?.original?.attributes?.consumption_sale_link ?
        <a href={row?.original?.attributes?.consumption_sale_link}>
          {row?.original?.attributes?.consumption_sale_link_text}
        </a> :
        <span>{row?.original?.attributes?.consumption_sale_text}</span>
      )
    )
  }

  const customerRow = (row) => {
    return (
      (row?.original?.attributes?.business_partner_name &&
        <a href={row?.original?.attributes?.business_partner_link}>
          {row?.original?.attributes?.business_partner_name}
        </a>
      )
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'invoice',
        header: t('invoice'),
        accessorFn: row => row?.attributes?.invoice_name,
        cell: ({ row }) => invoiceRow(row)
      },
      {
        id: 'date',
        accessorFn: row => row?.attributes?.bill_date,
        header: t('date'),
        cell: row => row.getValue()
      },
      {
        id: 'sale',
        accessorFn: row => row?.attributes?.consumption_sale,
        header: t('consumption_sale'),
        cell: ({ row }) => consumptionSaleRow(row)
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.customer,
        header: t('customer'),
        cell: ({ row }) => customerRow(row)
      },
      {
        id: 'quantity',
        accessorFn: row => row?.attributes?.quantity,
        header: t('shared.quantity'),
        cell: row => row.getValue()
      },
      {
        id: 'prices',
        accessorFn: row => row?.attributes?.price,
        header: t('prices'),
        cell: row => row.getValue()
      },
      {
        id: 'total_amount',
        accessorFn: row => row?.attributes?.total_amount,
        header: t('amount'),
        cell: row => row.getValue()
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getArticleGroups}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
